const API_URL = process.env.NEXT_PUBLIC_API_URL || 'http://localhost:3000';
const LEGACY_MY_PROJECTS_URL = process.env.NEXT_PUBLIC_LEGACY_MY_PROJECTS_URL;
const ANALYTICS_ENABLED = process.env.NEXT_PUBLIC_ANALYTICS_ENABLED || false;
const ANALYTICS_KEY = process.env.NEXT_PUBLIC_ANALYTICS_KEY || '';
const HELLO_SIGN_CLIENT_ID = process.env.NEXT_PUBLIC_HELLO_SIGN_CLIENT_ID;
const HELLO_SIGN_SKIP_DOMAIN_VERIFICATION = true;
//process.env.NEXT_PUBLIC_SIGNING_SKIP_DOMAIN_VERIFICATION?.toLowerCase() === 'true' || false;
const SYNCHRONY_APPLY_DOMAIN = process.env.NEXT_PUBLIC_SYNCHRONY_APPLY_DOMAIN;

const envs = ['development', 'staging', 'uat', 'production'];
export const getEnvironment = (env?: string) => envs.find((e) => e === env) || 'local';

enum SizeEnum {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

enum ColorTypeEnum {
  PRIMARY = 'primary',
  SUCCESS = 'success',
  DANGER = 'danger',
  DEFAULT = 'default',
  SURFACE_GREEN = 'surfaceGreen',
  SURFACE_BLUE = 'surfaceBlue',
  PAYPAL = 'paypal',
}

enum AlertTypeEnum {
  DANGER = 'danger',
  WARNING = 'warning',
  SUCCESS = 'success',
}

enum ToastTypeEnum {
  SUCCESS = 'success',
  DANGER = 'danger',
}

enum TextDecorationEnum {
  underline = 'text-decoration-underline',
  linethrough = 'text-decoration-line-through',
  none = 'text-decoration-none',
}

const sessionAge = 30 * 24 * 60 * 60; // 30 days

enum LangEnum {
  EN_US = 'en-US',
  FR_CA = 'fr-CA',
}

const QueryKey = {
  CUSTOMER_PROJECTS: 'customer-projects',
  DOCUMENTS: 'documents',
  DOCUMENT_LINKS: 'document-links',
  PROJECT_STATUS: 'project-status',
  ESTIMATES: 'estimates',
  ESTIMATE_PAYMENT: 'estimate-payment',
  WORKORDER_PAYMENT: 'workorder-payment',
  ACCOUNT_INFO: 'account-info',
  NEXT_ACTIONS: 'next-actions',
  WORK_ORDER: 'work-order',
  PAYMENT_HISTORY: 'payment-history',
  PAYMENT_CONFIRMATION: 'payment-confirmation',
  FINANCE_PROGRAMS: 'finance-programs',
  WORK_ORDER_ALERTS: 'work-order-alerts',
  GET_REQUIRED_DOCUMENTS: 'get-required-documents',
  WORK_ORDER_LIVE_CHAT: 'work-order-live-chat',
  WORK_ORDER_CREATE_SURVEY: 'work-order-create-survey',
  FEATURES: 'features',
} as const;

enum DocumentTypeEnum {
  LEAD_SAFE = 1,
  RIGHT_TO_CANCEL = 2,
  SALE_CONTRACT = 3,
}

const enum FormatDateEnum {
  DATE = 'MM/dd/yy',
  FULL_DATE = 'MM/dd/yyyy',
  DATE_TIME = 'MM/dd/yyyy h a',
  TIME = 'hh:mm a',
  YYYY_MM_DD = 'yyyy-MM-dd',
  HH_MM = 'HH:mm',
  H_MM_A = 'h:mm a',
  FULL_DATE_TIME = 'MM/dd/yyyy hh:mm:ss a',
}

const enum ExternalLinks {
  NEXT_STEPS_LOWES = 'https://ime.myhomeprojectcenter.com/media/3340/next-steps-lowes.pdf',
  PREPARE_FOR_YOUR_INSTALLATION_COUNTERTOPS = 'https://lowes.myhomeprojectcenter.com/tips-for-your-installation',
  PREPARING_FOR_THE_TEMPLATING_APPOINTMENT = 'https://uatlowes.myhomeprojectcenter.com/tips-for-measure-appointment',
}

const enum WorkOrderStatusEnum {
  APPOINTMENT_RUN = 'APPOINTMENT_RUN',
  SALE_PENDING = 'SALE_PENDING',
  COMPLETED = 'COMPLETED',
  INSTALL_SCHEDULED = 'INSTALL_SCHEDULED',
}

enum BusinessCategory {
  Default = 'Default',
  Countertops = 'Countertops',
  Sheds = 'Sheds',
  Bathrooms = 'Bathrooms',
  HVAC = 'HVAC',
  WaterHeaters = 'Water Heaters',
  Gutters = 'Gutters',
}

enum RequiredDocumentsGroup {
  ChangeOrder = 0,
  Sale = 30,
  Completion = 36,
}

const enum WorkOrderAlert {
  NoAlerts,
  SignSaleContract,
  SignCompletionCertificate,
  AcceptChangeOrder,
  SignCompletionCertificateNotComplete,
  CurrentStatusNotHandled = 5,
}

const RegExEnum = {
  EMAIL: new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/,
  ),
  SPACE: new RegExp(/^\s+$/),
} as const;

const SignedDocumentsStatusEnum = {
  TRUE: 'signedDocuments=true',
  FALSE: 'signedDocuments=false',
};

export {
  API_URL,
  ANALYTICS_ENABLED,
  ANALYTICS_KEY,
  SizeEnum,
  ColorTypeEnum,
  ToastTypeEnum,
  AlertTypeEnum,
  LangEnum,
  sessionAge,
  QueryKey,
  DocumentTypeEnum,
  FormatDateEnum,
  ExternalLinks,
  WorkOrderStatusEnum,
  BusinessCategory,
  WorkOrderAlert,
  RequiredDocumentsGroup,
  TextDecorationEnum,
  HELLO_SIGN_CLIENT_ID,
  HELLO_SIGN_SKIP_DOMAIN_VERIFICATION,
  RegExEnum,
  LEGACY_MY_PROJECTS_URL,
  SYNCHRONY_APPLY_DOMAIN,
  SignedDocumentsStatusEnum,
};
